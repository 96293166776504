$brand-color: #3f51b5;
$app-slogan-color: #757575;
$button-color: #3f51b5;
$button-hover-color: #313f8d;
$header-background-color: #ffffff;
$footer-s-background-color: #ffffff;
$footer-ml-background-color: #000000;
$shadow-color: #0000001c;
$boxing-shadow-color: #808080;
$usermenu-background-color: #ffffff;
$usermenu-text-color: #424242;
$usermenu-button-background--hover-color: #cccccc;
$sidebarLM-background-color: #f0f1f5;
$sidebarLM-text-color: #424242;
$sidebarLM-current-link-background-color: #757575;
$sidebarLM-current-link-text-color: #f0f1f5;
$sidebarLM-not-current-link-background-color: #f0f1f5;
$sidebarLM-button-background-color: #c2c2c2;
$tooltip-background-color: #808080;
$tooltip-border-color: #808080;
$tooltip-text-color: #ffffff;
$auth-footer-text-color: #d3d3d3;
$auth-text-color: #616161;
$auth-description-color: #818181;
$auth-success-color: #4caf50;
$auth-success-hover-color: #4caf50da;
$auth-error-color: #f44336;
$auth-error-hover-color: #f44336da;
$auth-input-border-color: #ffffff;
$auth-botton-border-color: #333;
$auth-submit-text-color: #ffffff;
$auth-submit-border-color: #3f51b5;
$auth-submit-background-color: #3f51b5;
$auth-submit-background-hover-color: #3f51b5ef;
$auth-submit-shadow-color: #616161;
$auth-password-eye-icon: #459ce7;
$auth-notification-close-icon-color: #ffffff;
$page-header-text-color: #424242;
$home-search-text-color: #757575;
$home-load-more-border-color: #ddd;
$home-load-more-text-hover-color: #ffffff;
$home-report-list-nav: #808080;
$report-status-created-color: #9e9e9e;
$report-status-delivered-color: #857979;
$report-status-seen-color: #2196f3;
$report-status-processing-color: #cddc39;
$report-status-pending-color: #ffeb3b;
$report-status-waiting-color: #ff9800;
$report-status-done-color: #4caf50;
$report-status-rejected-color: #f44336;
$report-address-color: #8bc34a;
$report-geographic-color: #9c27b0;
$input-error-color: #f44336;
$span-error-color: #f44336;
$loading-active-color: #3f51b5;
$loading-inactive-color: #f3f3f3;
$modal-header-color: #ffffff;
$modal-header-button-hover-color: #add8e6;
$modal-success-color: #4caf50;
$modal-error-color: #f44336;
$modal-info-color: #459ce7;
$modal-warning-color: #ff9800;
$dialog-color: #020202;
$list-path-color: #459ce7;
$list-icon-color: #016ABD;
$list-item-focus-color: #f0f8ff;
$create-report-icons-color: #e91e63;
$create-disabled-color: #d3d3d3;
$details-report-icons-color: #2196F3;
$profile-icons-color: #016ABD;
$feeder-search-text-color: #757575;
$feeder-load-more-border-color: #ddd;
$feeder-load-more-text-hover-color: #ffffff;
$feeder-report-list-nav: #808080;
$filterbar-icons-color: #016ABD;