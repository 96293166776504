.w3-theme-l5 {
  color: #000 !important;
  background-color: #f3f4fb !important;
}
.w3-theme-l4 {
  color: #000 !important;
  background-color: #d8dcf1 !important;
}
.w3-theme-l3 {
  color: #000 !important;
  background-color: #b1b8e3 !important;
}
.w3-theme-l2 {
  color: #fff !important;
  background-color: #8995d6 !important;
}
.w3-theme-l1 {
  color: #fff !important;
  background-color: #6271c8 !important;
}
.w3-theme-d1 {
  color: #fff !important;
  background-color: #3949a3 !important;
}
.w3-theme-d2 {
  color: #fff !important;
  background-color: #334191 !important;
}
.w3-theme-d3 {
  color: #fff !important;
  background-color: #2d397f !important;
}
.w3-theme-d4 {
  color: #fff !important;
  background-color: #26316d !important;
}
.w3-theme-d5 {
  color: #fff !important;
  background-color: #20295b !important;
}

.w3-theme-light {
  color: #000 !important;
  background-color: #f3f4fb !important;
}
.w3-theme-dark {
  color: #fff !important;
  background-color: #20295b !important;
}
.w3-theme-action {
  color: #fff !important;
  background-color: #20295b !important;
}

.w3-theme {
  color: #fff !important;
  background-color: #3f51b5 !important;
}
.w3-text-theme {
  color: #3f51b5 !important;
}
.w3-border-theme {
  border-color: #3f51b5 !important;
}

.w3-hover-theme:hover {
  color: #fff !important;
  background-color: #3f51b5 !important;
}
.w3-hover-text-theme:hover {
  color: #3f51b5 !important;
}
.w3-hover-border-theme:hover {
  border-color: #3f51b5 !important;
}

.w3-theme-notification {
  color: #1779ba !important;
  background-color: #d7ecfa !important;
  border-color: #1779ba !important;
}

a {
  color: #2196f3;
  text-decoration: none;
}

a.disabled {
  color: #616161;
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}

html,
body,
h1,
h2,
h3,
h4,
h5 {
  font-family: "Open Sans", sans-serif;
}

/* Remove border on active inputs */
.w3-input {
  border: none;
  background-color: white; // To reset bg color on date fields
}

/* Remove focus outline */
*:focus {
  outline: none !important;
}

/* Adding a new padding class for w3 */
.w3-padding-8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

/* Make the scrollbars thin */
// On chrome and Safari we can achive it with the following settings
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
::-webkit-scrollbar-button {
  background: #888;
}
::-webkit-scrollbar-track-piece {
  background: #fff;
}
::-webkit-scrollbar-thumb {
  background: #ccc;
}

// for Firefox add this class
* {
  scrollbar-width: thin;
}
/* End of scroll manipulation */

/* Switch */
/* The switch - the box around the slider */
.ct-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;

  /* Hide default HTML checkbox */
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .ct-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .ct-slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: #fff;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .ct-slider {
    background-color: #2196f3;
  }

  input:focus + .ct-slider {
    box-shadow: 0 0 2px #2196f3;
  }

  input:checked + .ct-slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .ct-slider.ct-round {
    border-radius: 34px;
  }

  .ct-slider.ct-round:before {
    border-radius: 50%;
  }
}
/* End of switch */

/* Make placeholders italic */

::-webkit-input-placeholder {
  font-style: italic;
}
:-moz-placeholder {
  font-style: italic;  
}
::-moz-placeholder {
  font-style: italic;  
}
:-ms-input-placeholder {  
  font-style: italic; 
}

/* End of italize placeholders */