@import "../../css/ct-colors";

/* Rotating loader's css*/
.ct-preloader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.ct-appName {
  line-height: 1;
  color: $brand-color;
  font-family: "Dancing Script";
  font-size: 2rem;
  font-style: italic;
  white-space: nowrap;
}
.ct-appSlogan {
  line-height: 4;
  overflow-wrap: unset;
  color: $app-slogan-color;
  font-family: "Open Sans";
  font-size: 1rem;
  font-style: oblique;
  white-space: nowrap;
}
.ct-rotate {
  animation: rotation 15s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}